'use client'

import { createContext, useContext, useEffect, useState } from "react"

import { useSession } from "next-auth/react"
import { toast } from "react-toastify"
import getCustomer from "@api/customer/get-customer"

const CustomerContext = createContext<any>(undefined)

type Customer = any

export function useCustomerContext() {
    return useContext(CustomerContext)
}

const CustomerProvider = ({ children }: { children: React.ReactNode }) => {
    const { data: session }: any = useSession()

    let [customer, setCustomer] = useState<{ [key: string]: Customer | undefined }>()

    useEffect(() => {
        if (!session?.user?.id) return
        const fetch = async () => {
            const { errors, ...customer } = await getCustomer({})

            errors?.forEach(({ detail }: any) => {
                toast.error(detail)
            })
            setCustomer(customer)
        }
        fetch()
    }, [session?.user?.id])

    const getCustomerAddresses = (addressId: string) => customer?.addresses?.find(({ id }: { id: string }) => addressId === id)

    return (
        <CustomerContext.Provider value={{
            customer,
            setCustomer,

            getCustomerAddresses
        }}>
            {children}
        </CustomerContext.Provider>
    )
}

export default CustomerProvider
