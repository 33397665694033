'use client'

import type { LogoutLinkProps } from "."

import { cloneElement } from "react"
import { signOut } from "next-auth/react"

const LogoutLink = ({ children }: LogoutLinkProps) => {

    return (
        <>{cloneElement(children, {
            onClick: (e) => {
                e.preventDefault()
                signOut({ callbackUrl: '/account/logout' })
            }
        })}</>
    )
}

export default LogoutLink
