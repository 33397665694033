'use client'

import type { AccountLinkProps } from "."

import { cloneElement } from "react"
import { usePathname } from "next/navigation"

const AccountLink = ({ children }: AccountLinkProps) => {
    const pathName = usePathname()

    return (
        <>{cloneElement(children, { className: `${children.props.className}${children.props.href === pathName ? ' fw-bold' : ''}` })}</>
    )
}

export default AccountLink
