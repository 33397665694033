import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/context/customer-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-bootstrap/esm/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-bootstrap/esm/Nav.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/react-bootstrap/esm/Row.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/account-link/AccountLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/logout-link/LogoutLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/salutation/Salutation.tsx");
