'use client'

import type { SalutationProps } from "."

import { useSession } from "next-auth/react"

const Salutation = ({ }: SalutationProps) => {
    const session: any = useSession()

    if (!session.data) {
        return `Hallo`
    }

    const { data: { user: { anonym, customer } } } = session

    return ((false === anonym && customer) ? (
        `Hallo ${customer.salutation.displayName ? ' ' + customer.salutation.displayName : ''} ${customer.salutation.displayName ? customer.lastName : customer.firstName}`
    ) : `Hallo`)
}

export default Salutation
